<template>
	<div class="doctor-list">
				
		<el-card class="filter-container" shadow="never">
			<div style="margin-top: 15px">
				<el-form :inline="true" size="small" label-width="100px">
					<el-form-item label="医生姓名：">
						<el-input style="width:300px" v-model="keyword" placeholder="医生姓名"></el-input>
					</el-form-item>
					<el-form-item label="所属科室：">
						<el-select v-model="deptNo" placeholder="选择所属科室">
							<el-option v-for="item in deptData" :key="item.deptNo" :label="item.deptName" :value="item.deptNo"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item style="float: right;">
						<el-button type="primary" size="small" @click="getonelist()">
							查询
						</el-button>
						<el-button type="info"  size="small" @click="clearlist">
							重置
						</el-button>
                        <el-button type="success" size="small" @click="goAdd('add')">
                            添加
                        </el-button>
                        <el-button type="info" size="small"  @click="del()" plain>
                            批量删除
                        </el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-card>

		<el-card style="margin-top: 20px;" shadow="never">
			<el-row justify="space-between" type="flex">
				<el-col><i class="el-icon-tickets"></i><span>医生管理列表</span></el-col>
			</el-row>
		</el-card>
		<el-card style="margin: 20px 0;" shadow="never">
			<el-table :data="list" style="width: 100%;" @selection-change="checkAll">
				<el-table-column type="selection" width="55" align="center">
				</el-table-column>
				<el-table-column label="排序" align="center">
					<template slot-scope="scope">
						<el-input style="width: 50px;" v-model="scope.row.sort" @blur="blur(scope)"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="id" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.id }}</span>
					</template>
				</el-table-column>
				<el-table-column label="医生姓名" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.doctorName }}</span>
					</template>
				</el-table-column>

				<el-table-column label="所属科室" align="center">
					<template slot-scope="scope">
						<!-- <el-image style="width: 40px; height: 40px" :src="src"></el-image> -->
						<span>{{ scope.row.deptName }}</span>
					</template>
				</el-table-column>

				<el-table-column label="职称" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.level }}</span>
					</template>
				</el-table-column>

				<el-table-column label="是否启用" align="center">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.isShow" @change="switchbtn(scope)">
						</el-switch>
					</template>
				</el-table-column>

				<el-table-column label="创建时间" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.createTime }}</span>
					</template>
				</el-table-column>

				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<p>
							<el-button size="mini" type="text" @click="goAdd('edit',scope)">修改</el-button>
                            <el-button @click="del(scope.row.id)" size="mini" type="text" >删除</el-button>
						</p>
						
					</template>
				</el-table-column>
			</el-table>
		</el-card>



		<el-pagination v-if="pageshow" :hide-on-single-page="hideonsinglepage" background :current-page="pageNo"
		 @current-change="pagebtn" :total="box.totalCount" :page-size="box.pageSize" layout="prev, pager, next">
		</el-pagination>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				keyword: '', //搜索
				list: [], //循环列表 
				row: { //点击选中的
					plugAdtypeId: '',
					plugAdtypeName: '',
					plugAdtypeOrder: '',
				},
				box: [], //全部数据
				hideonsinglepage: false, //分页
				allocDialogVisible: false, //控制弹窗显示
				modalAppendToBody: false, //弹窗插入位置
				apiurl: '', //接口地址
				pageNo: 1, //页码
				pageshow: true,
				deptNo:'',//医生科室
				checkData:[],
				deptData:[]
			}
		},
		methods: {
			// 查询
			getonelist(){
				const that=this;
				that.list=[];
				that.pageNo=1;
				that.getlist();
			},
			//跳转添加修改页
			goAdd(action,e){
				
				const that = this;
				that.$router.push({name: 'doctoradd',query:{id: e ?  e.row.id : '',ac:action}});
				
			},
			//获取科室列表
			getdepts() {
				const that = this;
				// /admin/department/list
				that.api('/api/department/list',{isShow: '1', parentNo: '0'}).then(res => {
					
					that.deptData = res.data;
					
				})
			},
			// 页数改变
			pagebtn(e) {
				const that = this;
				that.pageNo = e;
				that.getlist();
			},
			// 多选处理
			checkAll(val){
				let newVal = [];
				val.forEach(function(o,i){
					// newVal.push(o.id);
					newVal.push({"doctorId":o.id});
				});
				this.checkData = newVal;
			},
			// 删除方法
			del(delId){
				
				const that = this;
				let delData = [];
				if(delId){
					delData.push({"doctorId":delId});
				}
				else{
					delData = that.checkData;
				}
                this.$confirm('您确认要删除该医生信息么?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    that.api('/admin/doctor/del',delData).then(res=>{
                        if(res.code==200){
                            that.$message.success("删除成功!");
                            that.getlist();
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
				

				
			},
			// 单条排序
			blur(e) {
				const that = this;
				var data = [{
					doctorId: e.row.id,
					sort: Number(e.row.sort)
				}];
			
				that.api('/admin/doctor/editOrder', data).then(res => {
					if (res.code == 200) {
						that.getlist();
					}
				})
			},
			// 按钮开关
			switchbtn(e){
				const that=this;
				that.api('/admin/doctor/editState',{doctorId:e.row.id}).then(res=>{
					if(res.code==200){
						that.getlist();
					}
				})
			},
			// 获取列表
			getlist() {
				const that = this;
				that.api('/admin/doctor/list', {
					pageSize: 10,
					pageNo: that.pageNo,
					keyword: that.keyword,
					deptNo:that.deptNo,
					isDelete:0
				}).then(res => {
					for(var i =0; i<res.data.pageList.length;i++){
						if(res.data.pageList[i].isShow=='1'){
							res.data.pageList[i].isShow=true
						}else{
							res.data.pageList[i].isShow=false
						}
					}		
					that.box = res.data;
					that.list = res.data.pageList;
					that.pageNo = res.data.pageNo;
			
					this.pageshow = false; //让分页隐藏
					this.$nextTick(() => { //重新渲染分页
						this.pageshow = true;
					});
				})
			},
			// 重置搜索
			clearlist() {
				const that = this;
				that.keyword = "";
				that.deptNo = "";
				that.pageNo=1;
				that.getlist();
			}
			
			
			
		},
		created() {
			const that = this;
			that.getlist();
			that.getdepts();
		}
	}
</script>

<style lang="less" scoped>


</style>
